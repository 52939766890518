import { Button, css, Icon, Text } from "@storyofams/react-ui";
import { NavUnderline } from "~components/common/Icons";

interface Link {
  link?: string;
  text: string;
}

export const UnderlineLink: React.FC<Link> = ({ link, text }) => {
  return (
    <Button
      display="inline"
      cursor="pointer"
      css={css({
        ".underline": {
          position: "absolute",
          right: "-4px",
          height: 12,
          top: "1rem",
          bottom: 0,
          width: "calc(100% + 8px)",
          transition: "width 0.32s ease-in-out",
          zIndex: -1,
          svg: {
            width: "100%",
            height: "100%",
            color: "sand",
          },
        },
        "&&:hover": {
          ".underline": {
            width: "0",
          },
        },
      })}
    >
      {link ? (
        <a href={link} target="_blank" rel="noreferrer">
          <Text fontWeight="medium">{text}</Text>
        </a>
      ) : (
        <Text>{text}</Text>
      )}
      <Icon className="underline" icon={NavUnderline} width="100%" />
    </Button>
  );
};
