import { Fragment } from "react";
import { Box, Flex, Icon, SystemProps } from "@storyofams/react-ui";
import dynamic from "next/dynamic";
import { ArrowUp } from "~components/common/Icons";
import { Button as NewsLetterButton } from "~components/newsletter/components/Button";
import { NewsLetterImage } from "~components/newsletter/components/Image";
import { NewsLetterText } from "~components/newsletter/components/Text";
import type { Highlight as HighlightType } from "../blocks/Highlight";
import type { ABTest as ABTestType } from "../components/ABTest";
import type { Articles as ArticlesType } from "../components/Articles";
import type { Banner as BannerType } from "../components/Banner";
import type { Button as ButtonType } from "../components/Button";
import type { Checklist as ChecklistType } from "../components/Checklist";
import type { Circle as CircleType } from "../components/Circle";
import type { Columns as ColumnsType } from "../components/Columns";
import type { Stack as StackType } from "../components/Stack";
import ContactForm from "../components/ContactForm";
import type { FadedText as FadedTextType } from "../components/FadedText";
import FormEmail from "../components/FormEmail";
import FormName from "../components/FormName";
import FormQuestion from "../components/FormQuestion";

import type { Gallery as GalleryType } from "../components/Gallery";
import type { Image as ImageType } from "../components/Image";
import type { OrderList as OrderListType } from "../components/OrderList";
import type { ProductButton as ProductButtonType } from "../components/ProductButton";
import type { QuestionList as QuestionListType } from "../components/QuestionList";
import type { RichText as RichTextType } from "../components/RichText";
import type { StoryblokTable as StoryblokTableType } from "../components/StoryblokTable";
import type { DynamicTable as DynamicTableType } from "../components/Table";
import type { TableSmall as TableSmallType } from "../components/TableSmall";
import type { Title as TitleType } from "../components/Title";
import { UnderlineLink } from "../components/UnderlineLink";
import type { Video as VideoType } from "../components/Video";
import type { CodeEmbed as CodeEmbedType } from "./CodeEmbed";
import type { IngredientsCards as IngredientsCardsType } from "./IngredientsCards";
import type { ProductChoice as ProductChoiceType } from "./ProductChoice";
import { UspBar } from "./UspBar";
import { Spacer } from "./Spacer";

const Banner = dynamic(() =>
  import("../components/Banner").then((mod) => mod.Banner)
) as typeof BannerType;
const Button = dynamic(() =>
  import("../components/Button").then((mod) => mod.Button)
) as typeof ButtonType;
const Checklist = dynamic(() =>
  import("../components/Checklist").then((mod) => mod.Checklist)
) as typeof ChecklistType;
const CodeEmbed = dynamic(() =>
  import("./CodeEmbed").then((mod) => mod.CodeEmbed)
) as typeof CodeEmbedType;
const Columns = dynamic(() =>
  import("../components/Columns").then((mod) => mod.Columns)
) as typeof ColumnsType;
const Stack = dynamic(() =>
  import("../components/Stack").then((mod) => mod.Stack)
) as typeof StackType;
const Image = dynamic(() =>
  import("../components/Image").then((mod) => mod.Image)
) as typeof ImageType;
const Highlight = dynamic(() =>
  import("../blocks/Highlight").then((mod) => mod.Highlight)
) as typeof HighlightType;
const Gallery = dynamic(() =>
  import("../components/Gallery").then((mod) => mod.Gallery)
) as typeof GalleryType;
const ProductButton = dynamic(() =>
  import("../components/ProductButton").then((mod) => mod.ProductButton)
) as typeof ProductButtonType;
const QuestionList = dynamic(() =>
  import("../components/QuestionList").then((mod) => mod.QuestionList)
) as typeof QuestionListType;
const RichText = dynamic(() =>
  import("../components/RichText").then((mod) => mod.RichText)
) as typeof RichTextType;
const Text = dynamic(() =>
  import("~components/common/Text").then((mod) => mod.Text)
) as typeof RichTextType;
const Title = dynamic(() =>
  import("../components/Title").then((mod) => mod.Title)
) as typeof TitleType;
const Video = dynamic(() =>
  import("../components/Video").then((mod) => mod.Video)
) as typeof VideoType;
const Circle = dynamic(() =>
  import("../components/Circle").then((mod) => mod.Circle)
) as typeof CircleType;
const Table = dynamic(() =>
  import("../components/Table").then((mod) => mod.DynamicTable)
) as typeof DynamicTableType;
const StoryTable = dynamic(() =>
  import("../components/StoryblokTable").then((mod) => mod.StoryblokTable)
) as typeof StoryblokTableType;
const OrderList = dynamic(() =>
  import("../components/OrderList").then((m) => m.OrderList)
) as typeof OrderListType;
const FadedText = dynamic(() =>
  import("../components/FadedText").then((m) => m.FadedText)
) as typeof FadedTextType;
const TableSmall = dynamic(() =>
  import("../components/TableSmall").then((m) => m.TableSmall)
) as typeof TableSmallType;
const Articles = dynamic(() =>
  import("../components/Articles").then((m) => m.Articles)
) as typeof ArticlesType;
const ABTest = dynamic(() =>
  import("../components/ABTest").then((m) => m.ABTest)
) as typeof ABTestType;
const IngredientsCards = dynamic(() =>
  import("./IngredientsCards").then((mod) => mod.IngredientsCards)
) as typeof IngredientsCardsType;
const ProductChoice = dynamic(() =>
  import("./ProductChoice").then((mod) => mod.ProductChoice)
) as typeof ProductChoiceType;

const Components = {
  banner: Banner,
  button: Button,
  checklist: Checklist,
  code_embed: CodeEmbed,
  columns: Columns,
  highlight_small: Highlight,
  image: Image,
  gallery: Gallery,
  product_button: ProductButton,
  question_list: QuestionList,
  rich_text: RichText,
  title: Title,
  video: Video,
  circle_post: Circle,
  table: Table,
  simple_table: StoryTable,
  order_list: OrderList,
  contact_form: ContactForm,
  form_email: FormEmail,
  form_question: FormQuestion,
  form_name: FormName,
  text: FadedText,
  table_small: TableSmall,
  articles: Articles,
  ab_test: ABTest,
  usp_bar: UspBar,
  product_choice: ProductChoice,
  ingredient_cards: IngredientsCards,
  spacer_block: Spacer,
  stack: Stack,
};

type DynamicSectionProps = {
  first?: boolean;
  content?: any;
  parentComponent?: any;
} & SystemProps;

export const DynamicSection = ({
  content,
  first,
  parentComponent,
}: DynamicSectionProps) => {
  const goToSection = () => {
    const el = document?.getElementById("title");
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <Box
      bg={content?.bg_color_enabled ? content?.bg_color?.color : "inherit"}
      width={"100%"}
    >
      {content?.components?.length
        ? content?.components?.map((component, i) => {
            if (
              !!component &&
              typeof Components[component?.component] !== "undefined"
            ) {
              const Component = Components[component?.component];
              if (parentComponent === "community") {
                switch (component?.component) {
                  case "rich_text":
                    return (
                      <NewsLetterText
                        content={{ rich_text: component.text }}
                        columns={true}
                      />
                    );
                  case "button":
                    return (
                      <NewsLetterButton content={component} columns={false} />
                    );
                  case "image":
                    return (
                      <NewsLetterImage
                        content={{
                          Image: {
                            filename: component?.image?.filename,
                          },
                        }}
                        columns={false}
                      />
                    );
                  default:
                    return (
                      <>
                        <DynamicSection
                          content={{ components: component?.left_column }}
                          parentComponent={parentComponent}
                        />
                        <DynamicSection
                          content={{ components: component?.right_column }}
                          parentComponent={parentComponent}
                        />
                      </>
                    );
                }
              }
              return (
                <Box
                  mb={parentComponent === "columns" ? 0 : [1, 2]}
                  key={component?._uid}
                  width={"100%"}
                >
                  {component.component === "title" &&
                    parentComponent === "plant" && (
                      <Flex
                        cursor="pointer"
                        onClick={() => goToSection()}
                        fontSize="18px"
                        mb={[0, "40px"]}
                      >
                        <Icon icon={ArrowUp}></Icon>
                        <UnderlineLink text={"Terug naar inhoudsopgave"} />
                      </Flex>
                    )}
                  <Component
                    first={i === 0 && first}
                    content={component}
                    parentComponent={parentComponent}
                    text_align={content.text_align}
                    block
                  />
                </Box>
              );
            }

            return null;
          })
        : null}
    </Box>
  );
};
