import { Input } from "src/components";
const FormQuestion = ({ content }) => {
  return (
    <>
      <Input
        as="textarea"
        placeholder={content.form_question_placeholder}
        label={content.form_question_title}
      />
    </>
  );
};

export default FormQuestion;
