import emailjs from "@emailjs/browser";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Box } from "@storyofams/react-ui";

import { useForm } from "react-hook-form";
import { Input, Heading, Text, Button } from "src/components";
import * as Yup from "yup";
const ContactForm = ({ content }) => {
  const schema = Yup.object().shape({
    Email: Yup.string().required(),
    Name: Yup.string().required().max(100),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema) as any,
  });
  return (
    <>
      <Heading as="h1" variant="h3" textAlign="center" mb={-6}>
        {content.title}
      </Heading>
      <Text color="grey600" mb={-6} textAlign="center">
        {content.description}
      </Text>
      <Box
        as="form"
        onSubmit={handleSubmit((input) => {
          emailjs
            .send(
              process.env.NEXT_PUBLIC_SERVICE_ID,
              process.env.NEXT_PUBLIC_TEMPLATE_ID,
              input,
              process.env.NEXT_PUBLIC_PRIVATE_KEY
            )
            .then(
              () => {
                reset();
              },
              (error) => {
                console.log(error);
              }
            );
        })}
        width="60%"
      >
        <Stack flexDirection="column" space={3} width="100%">
          {content.form_fields.map((inp) => {
            switch (inp.component) {
              case "form_email":
                return (
                  <Input
                    type="email"
                    {...register("Email")}
                    error={String(errors?.Email?.message)}
                    placeholder={inp.form_email_placeholder}
                    label={inp.form_email_title}
                  />
                );
              case "form_name":
                return (
                  <Input
                    type="text"
                    {...register("Name")}
                    error={String(errors?.Name?.message)}
                    placeholder={inp.form_name_placeholder}
                    label={inp.form_name_title}
                  />
                );
              default:
                return (
                  <Input
                    as="textarea"
                    {...register("Message")}
                    placeholder={inp.form_question_placeholder}
                    label={inp.form_question_title}
                  />
                );
            }
          })}
          <Button
            variant="primary"
            width="100%"
            fontWeight="bold"
            color="white"
            type="submit"
          >
            {content.button_text}
          </Button>
        </Stack>
      </Box>
    </>
  );
};

export default ContactForm;
