import { Input } from "src/components";
const FormName = ({ content }) => {
  return (
    <>
      <Input
        type="text"
        placeholder={content.form_name_placeholder}
        label={content.form_name_title}
      />
    </>
  );
};

export default FormName;
