import { A, Box, Item, Span } from "react-html-email";

export const Button = ({ content, columns }) => {
  return (
    <Item
      align="center"
      style={{
        paddingTop: !columns && "30px",
        paddingBottom: !columns && "30px",
      }}
    >
      <Box
        width={columns ? "100%" : "285px"}
        height={!columns && "60px"}
        bgcolor={content.colour === "primary" ? "black" : "white"}
        align="center"
        cellPadding={15}
        style={{
          borderRadius: 5,
          border: content.colour === "secondary" ? "1px solid black" : "none",
        }}
      >
        <Item align="center">
          <A
            href={
              content?.url?.linktype === "story"
                ? "https://www.naturalheroes.nl/".concat(
                    content?.url?.cached_url
                  )
                : content?.url?.url
            }
            textDecoration="none"
          >
            <Span
              style={{
                textTransform: "uppercase",
                fontWeight: 700,
                fontSize: 16,
                color: content.colour === "primary" ? "white" : "black",
                fontFamily: "Arial",
              }}
            >
              {content.label}
            </Span>
          </A>
        </Item>
      </Box>
    </Item>
  );
};
