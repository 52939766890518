import { A, Box, Image, Item } from "react-html-email";

export const NewsLetterImage = ({ content, columns }) => {
  return (
    <>
      <Item>
        <Box align="center" width={columns ? "255px" : "570px"}>
          <Item>
            <A
              href={
                content?.url?.linktype === "story"
                  ? "https://www.naturalheroes.nl/".concat(
                      content?.url?.cached_url
                    )
                  : content?.url?.url
              }
              textDecoration="none"
            >
              <img
                alt="text"
                src={content?.Image.filename}
                width={columns ? "275px" : "570px"}
                style={{ borderRadius: "8px" }}
              />
            </A>
          </Item>
        </Box>
        {/* <Image
        src={content?.Image?.filename}
        width={570}
        height="auto"
        alt={"Image"}
        style={{ borderRadius: "10px" }}
      /> */}
      </Item>
      <br />
    </>
  );
};
