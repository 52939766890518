import { Box, Item, Span, A } from "react-html-email";

export const NewsLetterText = ({ content, columns }) => {
  const headings = {
    "1": 32,
    "2": 26,
    "3": 22,
    "4": 20,
    "5": 13,
    "6": 11,
  };
  return (
    <Item>
      <Box
        width={columns ? "100%" : "570px"}
        align={columns ? "left" : "center"}
      >
        {content?.rich_text.content &&
          content?.rich_text?.content.map((item) => {
            if (item.type === "paragraph") {
              return (
                <>
                  <Item>
                    {item?.content?.map((span) => {
                      if (span.type === "hard_break") return <br />;
                      const marks = span.marks
                        ? span.marks.map((mark) => mark.type)
                        : [];
                      if (marks.includes("link"))
                        return (
                          <A
                            href={
                              span.marks[0].attrs.linktype === "story"
                                ? "https://www.naturalheroes.nl/".concat(
                                    span.marks[0].attrs.href
                                  )
                                : span.marks[0].attrs.href
                            }
                          >
                            <SimpleText marks={marks} item={span} link />
                          </A>
                        );
                      else return <SimpleText marks={marks} item={span} />;
                    })}
                  </Item>
                  <Item>
                    <br />
                  </Item>
                </>
              );
            }
            if (item.type === "heading") {
              return item?.content?.map((span) => {
                const marks = span.marks
                  ? span.marks.map((mark) => mark.type)
                  : [];
                return (
                  <Item>
                    <Span
                      fontFamily="Arial"
                      lineHeight={27}
                      fontSize={headings[item.attrs.level]}
                      fontWeight="bold"
                      style={{
                        fontStyle: marks.includes("italic")
                          ? "italic"
                          : "normal",
                        textDecoration: marks.includes("underline")
                          ? "underline"
                          : marks.includes("strike")
                          ? "line-through"
                          : "none",
                      }}
                    >
                      {span.text}
                    </Span>
                  </Item>
                );
              });
            }
            if (item.type === "horizontal_rule") {
              return (
                <>
                  <Item style={{ borderTop: "1px solid #1D1D1B33" }} />
                  <Item>
                    <br />
                  </Item>
                </>
              );
            }
            if (item.type === "bullet_list") {
              return (
                <Item>
                  <ul style={{ paddingLeft: "20px" }}>
                    {item.content.map((li) => {
                      if (li.type === "bullet_list") {
                        return (
                          <Item>
                            <ul style={{ paddingLeft: "20px" }}>
                              {item.content.map((li) => {
                                return (
                                  <li>
                                    {li?.content[0].content &&
                                      li?.content[0].content.map((item) => {
                                        const marks = item.marks
                                          ? item.marks.map((mark) => mark.type)
                                          : [];
                                        return (
                                          <SimpleText
                                            marks={marks}
                                            item={item}
                                          />
                                        );
                                      })}
                                  </li>
                                );
                              })}
                            </ul>
                          </Item>
                        );
                      }
                      return (
                        <li>
                          {li?.content[0].content &&
                            li?.content[0].content.map((item) => {
                              const marks = item.marks
                                ? item.marks.map((mark) => mark.type)
                                : [];
                              if (marks.includes("link"))
                                return (
                                  <A
                                    href={
                                      item.marks[0].attrs.linktype === "story"
                                        ? "https://www.naturalheroes.nl/".concat(
                                            item.marks[0].attrs.href
                                          )
                                        : item.marks[0].attrs.href
                                    }
                                  >
                                    <SimpleText
                                      marks={marks}
                                      item={item}
                                      link
                                    />
                                  </A>
                                );
                              return <SimpleText marks={marks} item={item} />;
                            })}
                        </li>
                      );
                    })}
                  </ul>
                </Item>
              );
            }
            if (item.type === "ordered_list") {
              return (
                <Item>
                  <ol style={{ paddingLeft: "20px" }}>
                    {item.content.map((li) => {
                      if (li.type === "ordered_list") {
                        return (
                          <Item>
                            <ol style={{ paddingLeft: "20px" }}>
                              {item.content.map((li) => {
                                return (
                                  <li>
                                    {li?.content[0].content &&
                                      li?.content[0].content.map((item) => {
                                        const marks = item.marks
                                          ? item.marks.map((mark) => mark.type)
                                          : [];
                                        return (
                                          <SimpleText
                                            marks={marks}
                                            item={item}
                                          />
                                        );
                                      })}
                                  </li>
                                );
                              })}
                            </ol>
                          </Item>
                        );
                      }
                      return (
                        <li>
                          {li?.content[0].content &&
                            li?.content[0].content.map((item) => {
                              const marks = item.marks
                                ? item.marks.map((mark) => mark.type)
                                : [];
                              return <SimpleText marks={marks} item={item} />;
                            })}
                        </li>
                      );
                    })}
                  </ol>
                </Item>
              );
            }
            return null;
          })}
        {!columns && <br />}
      </Box>
    </Item>
  );
};
const SimpleText = ({ item, marks, link = false }) => {
  if (link)
    return (
      <div
        style={{
          fontFamily: "Arial",
          background:
            "url(https://a.storyblok.com/f/125270/292x44/e40ccaaf27/underline.png) bottom no-repeat ",
          backgroundSize: "100% 45%",
          backgroundPosition: "0 0.9em",
          display: "inline-block",
          height: "1.6em",
          lineHeight: "27px",
          fontSize: "18px",
          color: "black",
        }}
      >
        {item.text}
      </div>
    );
  return (
    <Span
      fontFamily="Arial"
      lineHeight={27}
      fontSize={18}
      color={"black"}
      fontWeight={marks.includes("bold") ? "bold" : "normal"}
      style={{
        fontStyle: marks.includes("italic") ? "italic" : "normal",
        textDecoration: marks.includes("underline")
          ? "underline"
          : marks.includes("strike")
          ? "line-through"
          : "none",
      }}
    >
      {item.text}
    </Span>
  );
};
